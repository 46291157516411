import React, { useEffect, useRef, useState } from 'react'
import { cards } from 'utils/constants';
import { getAvatar } from 'utils/helpers';

const Steps = {
    START: 0,
    IN: 1,
    CENTER: 2,
    OUT: 3,
    PAUSE: 4,
};

const Timing = {
    START: 3000,
    IN: 500,
    CENTER: 12000,
    OUT: 500,
};


const CardCarousel = () => {
    const [index, setIndex] = useState(0);
    const [step, setStep] = useState(0);
    const timers = useRef({
        START: null,
        IN: null,
        CENTER: null,
        OUT: null,
    });

    const updateSteps = () => {
        switch (step) {
            case Steps.START: {
                timers.current.START = setTimeout(() => {
                    setStep(Steps.CENTER);
                    // document.querySelector(".loading").remove();
                }, Timing.START);
                break;
            }

            case Steps.IN: {
                timers.current.IN = setTimeout(() => {
                    setStep(Steps.CENTER);
                }, Timing.IN);
                break;
            }

            case Steps.CENTER: {
                timers.current.CENTER = setTimeout(() => {
                    setStep(Steps.OUT);
                }, Timing.CENTER);
                break;
            }

            case Steps.OUT: {
                timers.current.OUT = setTimeout(() => {
                    setStep(Steps.IN);
                    setIndex((prev) => (prev + 1) % cards.length);
                }, Timing.OUT);
                break;
            }
        }
    };

    const pauseSteps = () => {
        if (step === Steps.CENTER) {
            setStep(Steps.PAUSE);
            clearTimeout(timers?.current?.CENTER);
        }
    };

    const continueSteps = () => {
        if (step === Steps.PAUSE) {
            setStep(Steps.CENTER);
        }
    };

    useEffect(() => {
        updateSteps();
    }, [step]);

    useEffect(() => {
        return () => {
            Object.values(timers.current).forEach((timer) => {
                clearTimeout(timer);
            });
        };
    }, []);

    return (
        <div className="carousel-container">
            <div
                className={`carousel-card step${step}`}
                onMouseOver={pauseSteps}
                onMouseOut={continueSteps}
            >
                <div className="card-image">
                    <img src={cards[index].avatar} alt={cards[index].name} />
                </div>
                <div className="card-content">
                    <div className="card-header">
                        <div className="info">
                            <h3>{cards[index].name}</h3>
                            <p className='location'>{cards[index].location.city} {cards[index].location.street}</p>
                        </div>
                        <span>⭐⭐⭐⭐⭐</span>
                    </div>
                    <blockquote>
                        {cards[index].review}
                    </blockquote>
                    <div className="tags">
                        {cards[index].tags.map((tag, i) => (
                            <span key={i} className="tag">
                                {tag}
                            </span>
                        ))}
                    </div>


                </div>
            </div>
        </div>
    )
}

export default CardCarousel